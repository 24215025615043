import React from "react";
import "./index.css";
import "./theme.css";
import "./css/index.css";

import { createRoot } from "react-dom/client";
import { Capacitor } from "@capacitor/core";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";

global.platform = Capacitor.getPlatform();

document.documentElement.style.setProperty("--block-icon", localStorage.getItem("block-icon") || `url(/blockAssets/box-1.png)`);
document.documentElement.style.setProperty("--expold-icon", localStorage.getItem("explod-icon") || `url(/blockAssets/box-5.png)`);

const index = async () => {
    document.body.className = localStorage.getItem("theme") || (window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light");
    createRoot(document.getElementById("root")).render(
        <Router>
            <App />
        </Router>
    );
};
index();
