import React from "react";
import { Div, P, Span } from "../scripts/NodeBuilder";
import "./blockgame.css";
import PopupSettings from "./PopupSettings";
const BoxClone = Div({ className: "box" });

let _level = parseInt(localStorage.getItem("level") || 6);

let boxes = [[]];
let game = {};
let clone = null;
let startTime = new Date().getTime();
const customCode = -1;

const containerId = "box-container";
let score = 0;
const onBoxClick = (e) => {
    const { id } = e.target;
    const [r, c] = id.split("-");
    const box = boxes[r][c];
    // box.classList.add("box-taken");
    // box.setAttribute("is", "hoverd");
};
const Scores = localStorage.getItem("scores") ? JSON.parse(localStorage.getItem("scores")) : [];
const rand = [2, 3];

const getBoxes = ({ cols, rows }) => {
    const _boxes = [];
    for (let r = 0; r < rows; r++) {
        for (let c = 0; c < cols; c++) {
            const _box = BoxClone.cloneNode(true);
            _box.addEventListener("click", onBoxClick);
            _box.id = `${r}-${c}`;
            _box.setAttribute("localname", `t${rand[Math.floor(Math.random() * rand.length)]}`);
            _boxes.push(_box);
        }
    }
    return _boxes;
};

const checkPlace = (targetBox) => {
    if (!game.shapeMoved || game.lastHoveredId === targetBox.id || targetBox.id === containerId) return;
    game.lastHoveredId = targetBox.id;
    game.shapeMoved(targetBox);
};

const BlockGame = () => {
    const [level, setLevel] = React.useState(_level);
    React.useEffect(() => {
        setTimeout(() => {
            const container = document.getElementById(containerId);
            createShape({ container, rows: _level, cols: _level });
            container.onmousemove = ({ target }) => {
                checkPlace(target, true);
            };
            container.addEventListener("mouseleave", (e) => {
                if (!game.boxHoverTirgerd) return;
                game.boxHoverTirgerd(false);
            });
            checkToAddNewShapes(true);
        }, 0);
        localStorage.setItem("level", level);

        document.documentElement.style.setProperty("--gap", `${20 / level}px`);
    }, [level]);

    React.useEffect(() => {
        window.addEventListener("mousemove", (e) => {
            if (!game.shapeGrabed) return;
            game.shapeGrabed(e.clientX, e.clientY);
        });

        window.addEventListener("touchmove", (e) => {
            const target = document.elementFromPoint(e.touches[0].clientX, e.touches[0].clientY - 140);
            if (target) {
                checkPlace(target, true);
            }
            if (game.shapeGrabed) {
                game.shapeGrabed(e.touches[0].clientX, e.touches[0].clientY - 140);
            }
        });

        window.addEventListener("touchend", (e) => {
            if (game.shapeDroped) game.shapeDroped();
            if (game.boxHoverTirgerd) game.boxHoverTirgerd(false);
        });
        window.addEventListener("touchcancel", (e) => {
            if (game.shapeDroped) game.shapeDroped();
            if (game.boxHoverTirgerd) game.boxHoverTirgerd(false);
        });
        window.addEventListener("mousedown", (e) => {
            if (!game.shapeDroped) return;
            game.shapeDroped();
        });
    }, []);
    return (
        <div className="main-container">
            <div className="sorce-container">
                <p className="font-bold  text-center" id="score">
                    {score}
                </p>
                <div id="box-score" className="box" />
            </div>
            <div id={containerId}></div>
            <div id="box-holder" className="box-holder"></div>
            <PopupSettings />
            <div className="scores-container scroller">
                {Scores.reverse().map((score, i) => {
                    return (
                        <div key={i} className="score-item font-bold text-left">
                            <p className="font-bold">
                                <span className="text-sm" style={{ color: "#999" }}>
                                    Score :
                                </span>
                                {+score.score}
                            </p>
                            {/* <p>{score.level}</p> */}
                            <p className="" style={{ fontSize: 10, opacity: 0.5 }}>
                                {score.time}
                            </p>
                            {/* <p>{Math.round(score.timeSpend / 6_000)}</p> */}
                        </div>
                    );
                })}
            </div>
            <div className="level-container">
                <div className="level-box">
                    <p
                        className="btn"
                        onClick={() => {
                            _level = level + 1;
                            setLevel(_level);
                        }}>
                        +
                    </p>
                    <p className="font-bold text-center">
                        <spn>Level</spn>
                        <br />
                        <spn>{level}</spn>
                    </p>
                    <p
                        className="btn"
                        onClick={() => {
                            if (level - 1 < 2) return;
                            _level = level - 1;
                            setLevel(_level);
                        }}>
                        -
                    </p>
                </div>
            </div>
        </div>
    );
};

export default BlockGame;

const gameOver = () => {
    document.getElementById("score").innerText = "Game Over \n Score: " + score;

    Scores.push({
        score,
        level: _level,
        time: new Date().toLocaleString(),
        startTime,
        timeSpend: new Date().getTime() - startTime,
    });
    startTime = new Date().getTime();
    localStorage.setItem("scores", JSON.stringify(Scores));

    setTimeout(() => {
        document.getElementById("score").innerText = "Game Over \n Score: " + score;
        window.addEventListener("click", () => {
            window.location.reload();
        });
    }, 1000);
    document.getElementsByClassName("main-container")[0].classList.add("game-over");
};

function checkToAddNewShapes(init) {
    const holder = document.getElementById("box-holder");
    if (init) {
        holder.innerHTML = "";
        for (let s = 0; s < 3; s++) holder.append(getShpaeCode(s));
        return;
    }
    Object.values(holder.childNodes).forEach((child) => {
        let fits = [];
        boxes.forEach((box) => {
            if (fits.length > 1) return;
            if (checkShapeFitAll(box, child, false)) fits.push(box.id);
        });
        child.setAttribute("is", fits.length < 1 ? "disabled" : "enabled");
    });
    document.querySelectorAll("[is='disabled']").length === holder.childElementCount && gameOver();
}

function createShape({ container, rows, cols }) {
    container.innerHTML = "";
    container.style.gridTemplateRows = `repeat(${rows}, 1fr)`;
    container.style.gridTemplateColumns = `repeat(${cols}, 1fr)`;

    boxes = getBoxes({ rows, cols });
    container.append(...boxes);
    // Object.values(boxes).forEach((row) => {
    //     row.forEach((box) => {
    //         container.append(box);
    //     });
    // });
}

const getShpaeCode = (code) => {
    // const _shape = shapeCodes[code] || shapeCodes[Math.floor(Math.random() * shapeCodes.length)];
    code = customCode > -1 ? customCode : Math.floor(Math.random() * shapeCodes.length);
    const _shape = shapeCodes[code];

    let startX = 0;
    let startY = 0;
    const onChoseShape = (e) => {
        const currentTarget = e.currentTarget;
        if (currentTarget.getAttribute("is") === "disabled") return;
        clone?.clean();

        const boxContainer = document.getElementById(containerId);
        const offset = ((boxContainer.clientWidth - 20) / _level) * 3;

        if (e.touches) {
            startX = e.touches[0].clientX;
            startY = e.touches[0].clientY;
        } else {
            startX = e.clientX;
            startY = e.clientY;
        }

        clone = currentTarget.cloneNode(true);

        currentTarget.style.opacity = 0;
        clone.clean = (all) => {
            clone.remove();
            if (all) {
                currentTarget.replaceWith(getShpaeCode());
                setTimeout(() => {
                    checkToAddNewShapes();
                }, 10);
            } else currentTarget.style.opacity = 1;
        };

        clone.classList.add("shape-clone");
        clone.style.left = `${startX}px`;
        clone.style.top = `${startY}px`;

        clone.style.width = `${offset}px`;
        clone.style.height = `${offset}px`;
        clone.style.margin = `-${offset / 2}px`;

        document.body.append(clone);

        game.boxHoverTirgerd = (entered) => {
            if (clone.entered === entered) return;
            clone.entered = entered;
            if (entered) {
                clone.entered = true;
                clone.classList.add("shape-take-place");
                game.shapeGrabed = null;
            } else {
                game.lastHoveredId = null;
                clearBreacks();
                clone.classList.remove("shape-take-place");
                game.shapeGrabed = (x, y) => {
                    const dx = x; //- startX;
                    const dy = y; //- startY;
                    clone.style.left = `${dx}px`;
                    clone.style.top = `${dy}px`;
                };
            }
        };
        game.boxHoverTirgerd(false);

        game.shapeMoved = (hoverdBox) => {
            const fit = checkShapeFit(hoverdBox, currentTarget);
            game.boxHoverTirgerd(fit);
        };
    };

    const shapeContainer = Div({ id: code, className: "shape-container", onmousedown: onChoseShape, ontouchstart: onChoseShape });

    const maxRows = _shape.length;
    const maxCols = Math.max(..._shape.map((row) => row.length));
    shapeContainer.style.gridTemplateRows = `repeat(${maxRows}, 1fr)`;
    shapeContainer.style.gridTemplateColumns = `repeat(${maxCols}, 1fr)`;
    Object.values(_shape).forEach((row, r) => {
        let _row = [...row];
        _row.reverse().forEach((col, c) => {
            if (col) {
                const _box = BoxClone.cloneNode(true);
                _box.id = `${r}-${c}-${code}`;
                _box.style.gridRow = `${r + 1}`;
                _box.style.gridColumn = `${c + 1}`;
                _box.setAttribute("localname", `t${rand[Math.floor(Math.random() * rand.length)]}`);

                shapeContainer.append(_box);
            } else {
            }
        });
    });

    return shapeContainer;
};

const clearBreacks = () => {
    document.querySelectorAll(".box-chosen").forEach((box) => cleanBox(box));
    document.querySelectorAll("[is=box-breack]").forEach((box) => box.setAttribute("is", "box"));
};

const cleanBox = (boxToExpold, breack) => {
    if (breack) {
        let _box = BoxClone.cloneNode(true);
        _box.id = "";
        _box.className = "box box-explode";
        _box.style.left = `${boxToExpold.offsetLeft}px`;
        _box.style.top = `${boxToExpold.offsetTop}px`;
        _box.style.width = `${boxToExpold.offsetWidth - 7}px`;
        _box.style.height = `${boxToExpold.offsetHeight - 7}px`;
        _box.style.position = "fixed";
        _box.style.zIndex = 300;

        const aniOne = 600;
        const aniTwo = 600;
        const boxSiz = 60;
        setTimeout(() => {
            document.body.append(_box);

            const direction = Math.random() > 0.5;
            const transfromOffset = (direction ? 1 : -1) * 5;
            const rotateOffset = (direction ? 1 : -1) * Math.random() * 720;
            _box.style.transition = `all ${aniOne * Math.random()}ms ease-in-out`;

            const left = boxToExpold.offsetLeft + Math.random() * transfromOffset;
            _box.style.left = `${left}px`;
            _box.style.top = `${boxToExpold.offsetTop + Math.random() * transfromOffset}px`;
            _box.style.transform = `rotate(${rotateOffset}deg) scale(0.1)`;

            setTimeout(() => {
                _box.style.transform = `rotate(${rotateOffset}deg) scale(0.9)`;
                _box.style.margin = "0";
                _box.style.transition = `all ${aniTwo * Math.random()}ms ease-in-out`;
                _box.style.top = `${document.getElementById("box-score").offsetTop}px`;
                _box.style.left = `${document.getElementById("box-score").offsetLeft}px`;
                _box.style.height = boxSiz + "px";
                _box.style.width = boxSiz + "px";
                _box.style.zIndex = "300 !important";
                _box.style.transform = "scale(0.9)";
                // setTimeout(() => {
                //     _box.style.transition = "all 100ms ease-in-out";
                //     _box.style.transform = "scale(0)";
                // }, aniTwo);
            }, aniOne);
        }, 10);

        setTimeout(() => {
            _box.remove();
        }, aniOne + aniTwo);
    }
    boxToExpold.className = "box";
    boxToExpold.setAttribute("is", "box");
    boxToExpold.setAttribute("row", "");
    boxToExpold.setAttribute("col", "");
};
const checkShapeFit = (hoverdBox, shape, place = true) => {
    clearBreacks();
    let fit = true;
    const centered = hoverdBox.id.split("-").map((v) => parseInt(v));
    const pointerRow = centered[0];
    const pointerCol = centered[1];

    let rowsToCheck = [];
    let colsToCheck = [];

    const otherChecks = [
        [pointerRow, pointerCol],
        [pointerRow + 1, pointerCol],
        [pointerRow - 1, pointerCol],
        [pointerRow, pointerCol + 1],
        [pointerRow, pointerCol - 1],
        [pointerRow + 1, pointerCol + 1],
        [pointerRow - 1, pointerCol - 1],
        [pointerRow - 1, pointerCol + 1],
        [pointerRow + 1, pointerCol - 1],
    ];

    // document.querySelectorAll(".box-explode").forEach((box) => box.classList.remove("box-explode"));
    // otherChecks.forEach((toCheck) => {
    //     const BOX = document.getElementById(`${toCheck[0]}-${toCheck[1]}`);
    //     if (BOX) {
    //         BOX.classList.add("box-explode");
    //     }
    // });

    const shapeCode = shapeCodes[parseInt(shape.id)];

    for (let t = 0; t < otherChecks.length; t++) {
        fit = true;
        const toCheck = otherChecks[t];
        game.chosenBoxes = [];
        rowsToCheck = [];
        colsToCheck = [];
        //convert to for
        for (let sr = 0; sr < shapeCode.length; sr++) {
            const row = shapeCode[sr];
            const r = sr - 1;
            for (let sc = 0; sc < row.length; sc++) {
                const col = row[sc];
                const c = sc - 1;
                if (!col) continue;
                const chosenRow = toCheck[0] + r;
                const chosenCol = toCheck[1] - c;

                const boxId = `${chosenRow}-${chosenCol}`;
                !colsToCheck.includes(chosenCol) && colsToCheck.push(chosenCol);
                !rowsToCheck.includes(chosenRow) && rowsToCheck.push(chosenRow);

                const box = document.getElementById(boxId);
                if (!box || box.classList.contains("box-taken")) {
                    fit = false;
                    game.chosenBoxes = [];
                    break;
                } else game.chosenBoxes.push(box);
            }
        }
        if (fit) break;
    }

    if (fit) {
        if (!place) {
            return fit;
        }
        game.chosenBoxes.forEach((box) => {
            box.classList.add("box-chosen");
            box.setAttribute("row", `${box.id.split("-")[0]}`);
            box.setAttribute("col", `${box.id.split("-")[1]}`);
        });
        checkShapeBreack(rowsToCheck, colsToCheck);

        game.shapeDroped = () => {
            if (clone.classList.contains("shape-take-place") && game.chosenBoxes?.length > 0) {
                game.chosenBoxes.forEach((box) => {
                    box.classList.remove("box-chosen");
                    box.classList.add("box-taken");
                });
                const breackBoxes = document.querySelectorAll("[is=box-breack]");
                const sorceEl = document.getElementById("score");

                for (let i = 0; i < breackBoxes.length; i++) {
                    setTimeout(() => {
                        score += 5 * i;
                        sorceEl.innerText = score;
                    }, 100 * i);
                }

                breackBoxes.forEach((__box) => {
                    cleanBox(__box, true);
                });
                clone.clean(true);
            }
            game = {};
            clone.clean();
        };
    } else {
        game.shapeDroped = () => {
            game.chosenBoxes = [];
            clone.clean();
            game.shapeDroped = null;
        };
    }

    return fit;
};

const checkShapeBreack = (rowsToCheck, colsToCheck) => {
    rowsToCheck.forEach((r) => {
        const chosenRowsToBreack = [...document.querySelectorAll(`[row="${r}"]`)];
        if (chosenRowsToBreack.length === _level)
            chosenRowsToBreack.forEach((box) => {
                box.setAttribute("is", "box-breack");
            });
    });
    colsToCheck.forEach((c) => {
        const chosenColsToBreack = [...document.querySelectorAll(`[col="${c}"]`)];
        if (chosenColsToBreack.length === _level)
            chosenColsToBreack.forEach((box) => {
                if (box.getAttribute("is") !== "box-breack") box.setAttribute("is", "box-breack");
            });
    });
};

const shapeCodes = [
    [
        //
        [0, 0, 0],
        [0, 1, 0],
        [0, 0, 0],
    ],
    [
        //     //
        [0, 0, 0],
        [0, 1, 1],
        [0, 0, 0],
    ],
    [
        //     //
        [0, 1, 0],
        [0, 1, 0],
        [0, 0, 0],
    ],
    [
        //
        [0, 0, 0],
        [1, 1, 1],
        [0, 0, 0],
    ],
    [
        [0, 0, 0],
        [1, 1, 1],
        [1, 0, 0],
    ],
    [
        [0, 0, 0],
        [1, 1, 1],
        [0, 1, 0],
    ],
    [
        [0, 0, 0],
        [1, 1, 1],
        [0, 0, 1],
    ],

    [
        [1, 1, 1],
        [0, 0, 1],
        [0, 0, 1],
    ],

    [
        [0, 1, 0],
        [0, 1, 0],
        [0, 1, 0],
    ],
    [
        [1, 1, 1],
        [1, 1, 1],
        [1, 1, 1],
    ],
    _level > 4
        ? [
              [1, 1, 1, 1],
              [1, 1, 1, 1],
              [1, 1, 1, 1],
              [0, 0, 0, 0],
          ]
        : [
              [1, 1, 1],
              [1, 1, 1],
              [1, 1, 1],
          ],
    [
        [1, 1, 1],
        [0, 1, 0],
        [1, 1, 1],
    ],
    [
        [0, 0, 0],
        [0, 1, 0],
        [1, 1, 1],
    ],
    // [
    //     [0, 0, 0, 0, 0],
    //     [1, 1, 1, 1, 1],
    //     [1, 1, 1, 1, 1],
    // ],
];
const checkShapeFitAll = (hoverdBox, shape) => {
    let fit = true;
    const [box_r, box_c] = hoverdBox.id.split("-").map((v) => parseInt(v));

    shapeCodes[parseInt(shape.id)].forEach((row, r) => {
        if (!fit) return;
        r -= 1;
        row.forEach((col, c) => {
            if (!fit || !col) return;
            c -= 1;
            const box = document.getElementById(`${box_r + r}-${box_c - c}`);
            fit = box && !box.classList.contains("box-taken");
        });
    });
    return fit;
};
