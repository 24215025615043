import React from "react";
// import Utils from "../Utils/Utils";
let level = localStorage.getItem("level") || 3;
const PopupSettings = () => {
    const [popup, setPopup] = React.useState(false);
    const [icons, setIcons] = React.useState(["/blockAssets/box-0.png"]);
    return popup ? (
        <div
            className="popup"
            id="7"
            onClick={(e) => {
                if (e.target.id == 7) setPopup(false);
            }}>
            <div className="popup-child">
                {/* <p
                    className="level-item font-bold"
                    onClick={() => {
                        level = 3;
                        localStorage.setItem("level", level);
                        window.location.reload();
                    }}>
                    Easy
                </p>
                <p
                    className="level-item font-bold"
                    onClick={() => {
                        level = 5;
                        localStorage.setItem("level", level);
                        window.location.reload();
                    }}>
                    Medium
                </p>
                <p
                    className="level-item font-bold"
                    onClick={() => {
                        level = 7;
                        localStorage.setItem("level", level);
                        window.location.reload();
                    }}>
                    Hard
                </p>
                <p
                    className="level-item font-bold"
                    onClick={() => {
                        level = 11;
                        localStorage.setItem("level", level);
                        window.location.reload();
                    }}>
                    Expert
                </p> */}

                <span className="col-span-fill font-bold text-sm text-left" style={{ marginBottom: -20 }}>
                    Icon
                </span>
                {icons.map((icon) => (
                    <img
                        alt={icon}
                        onLoad={(e) => {
                            setIcons((prev) => {
                                const nextIcon = `/blockAssets/box-${prev.length + 1}.png`;
                                return prev.includes(nextIcon) ? prev : [...prev, nextIcon];
                            });
                        }}
                        onError={(e) => {
                            setIcons((prev) => prev.filter((i) => i !== icon));
                        }}
                        onClick={(e) => {
                            setBlockIcon(icon);
                        }}
                        key={icon}
                        className="icon"
                        src={icon}
                    />
                ))}

                <CustomIcons iconKey="icon" />

                <span className="col-span-fill font-bold text-sm text-left" style={{ height: 20 }}>
                    Explod Icon
                </span>

                {icons.map((icon) => (
                    <img
                        alt={icon}
                        onLoad={(e) => {
                            setIcons((prev) => {
                                const nextIcon = `/blockAssets/box-${prev.length + 1}.png`;
                                return prev.includes(nextIcon) ? prev : [...prev, nextIcon];
                            });
                        }}
                        onError={(e) => {
                            setIcons((prev) => prev.filter((i) => i !== icon));
                        }}
                        onClick={(e) => {
                            setBlockIcon(icon, "expold-icon");
                        }}
                        key={icon}
                        className="icon"
                        src={icon}
                    />
                ))}
                {/* <CustomIcons iconKey="expold-icon" /> */}
            </div>
        </div>
    ) : (
        <div>
            <img onClick={() => setPopup(true)} className="settings-icon" src="fav.webp" />
        </div>
    );
};

export default PopupSettings;

const setBlockIcon = (icon, key = "block-icon") => {
    icon = `url(${icon})`;
    localStorage.setItem(key, icon);
    document.documentElement.style.setProperty(`--${key}`, icon);
};

const CustomIcons = ({ iconKey }) => {
    const key = `custom-icons-${iconKey}`;
    const [icons, setIcons] = React.useState([]);
    const addIcon = (e) => {
        var URL = window.webkitURL || window.URL;
        var url = URL.createObjectURL(e.target.files[0]);
        setIcons((prev) => {
            return [...prev, url];
        });
    };
    return (
        <>
            <input style={{ display: "none" }} id="input-new-icon" type="file" onChange={addIcon} />
            {icons.map((icon) => (
                <img
                    onClick={(e) => {
                        setBlockIcon(icon, iconKey);
                    }}
                    key={icon}
                    src={icon}
                    className="icon"
                />
            ))}
            <p
                style={{ columnSpan: "all" }}
                className="font-bold text-2xl text-center shadow-lg rounded-lg pointer "
                onClick={(e) => {
                    document.getElementById("input-new-icon").click();
                }}>
                +
            </p>
        </>
    );
};
const iconsSorceBox = [
    //
    "/blockAssets/box-0.png",
    "/blockAssets/box-1.png",
    "/blockAssets/box-2.png",
    "/blockAssets/box-3.png",
    "/blockAssets/box-4.png",
];
const iconsSorce = [
    //
    "/blockAssets/box-0.png",
    "/blockAssets/lol-1.png",
    "/blockAssets/lol-2.png",
    "/blockAssets/lol-3.png",
    "/blockAssets/box-4.png",
];
